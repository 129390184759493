const prefix = "SMR";

const set = (key, data) =>
  sessionStorage.setItem(`${prefix}_${key}`, JSON.stringify(data));
const get = (key) => JSON.parse(sessionStorage.getItem(`${prefix}_${key}`));
const del = (key) => JSON.parse(sessionStorage.getItem(`${prefix}_${key}`));
const update = (key, data) => {
  const getInfo = get(key);
  if (getInfo) {
    const newData = {
      ...getInfo,
      ...data,
    };
    set(key, newData);
  } else {
    set(key, data);
  }
};

export { set, get, del, update };
