import React from 'react'

export const Checkbox = ({checked, onChange,className,name}) => {
    return (
        <input
        name={name}
        type="checkbox"
        className={className}
        checked={checked}
        onChange={onChange}
      />
    )
}
