import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import { Div } from '../../atoms/Div';
import { Theme } from './../../molecules/quickdemo/Theme';
import { UseQuickdemo } from './useQuickdemo';

export const QuickDemo = ({visible,toggleUserActions}) => {
  
    const { quickdemo } = UseQuickdemo()

    return (
        <Collapse in={visible} className="quick-demo">
            <Div className="quick-demo__header">
              <Div className="quick-demo__title">
                Quick Demo Smartman
              </Div>
              <Div className="quick-demo__close" onClick={toggleUserActions}>

              </Div>
            </Div>

            <Div className="quick-demo__themes-sumary">
              {quickdemo.map((theme, idx)=>
                <Theme key={`mainTheme_${idx}`} theme={theme} idTheme={idx}></Theme>
              )}
            </Div>
        </Collapse>
    )
}
