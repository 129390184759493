import { types } from "../types/types";

const initialState = {
  modalStatus: {
    show: false,
    style: "success",
    icon: false,
    text: "",
  },
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.updateModalStatus:
      return {
        ...state,
        modalStatus: action.payload,
      };

    default:
      return state;
  }
};
