const UseQuickdemo = () => {
  const quickdemo = [
    {
      subthemes: [
        {
          withvideo: true,
          video: "/videos/QUICKDEMO_1_1_add_store.mp4",
          subsubtheme: [
            {
              section: [
                {
                  type: "collapse",
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  subsection: [
                    {
                      style: "steps",
                      content: [
                        {
                          value: 1,
                        },
                        {
                          value: 2,
                        },
                        {
                          value: 3,
                        },
                        {
                          value: 4,
                        },
                        {
                          value: 5,
                        },
                        {
                          value: 6,
                        },
                        {
                          value: 7,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              section: [
                {
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          video: "/videos/QUICKDEMO_1_2_sync_edit_delete.mp4",
          subsubtheme: [
            {
              section: [],
            },
            {
              section: [],
            },
            {
              section: [],
            },
          ],
        },
      ],
    },
    {
      subthemes: [
        {
          withvideo: false,
          subsubtheme: [],
        },
      ],
    },
    {
      subthemes: [
        {
          withvideo: false,
          subsubtheme: [
            {
              video: "/videos/QUICKDEMO_3_1_manual_sort.mp4",
              section: [],
            },
            {
              video: "/videos/QUICKDEMO_3_2_multiple_sort.mp4",
              section: [],
            },
            {
              video: "/videos/QUICKDEMO_3_3_mass_sort.mp4",
              section: [
                {
                  type: "point",
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  type: "point",
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
                {
                  type: "point",
                  subsection: [
                    {
                      style: "none",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          withvideo: true,
          video: "/videos/QUICKDEMO_3_sort_criteria.mp4",
          subsubtheme: [
            {
              section: [],
            },
          ],
        },
      ],
    },
    {
      subthemes: [
        {
          withvideo: true,
          video: "/videos/QUICKDEMO_4_index.mp4",
          subsubtheme: [
            {
              section: [],
            },
            {
              section: [],
            },
            {
              section: [],
            },
          ],
        },
      ],
    },
  ];

  return {
    quickdemo,
  };
};

export { UseQuickdemo };
