import { catalogApi } from "./config";

const getListMagicalOrderingCategories = async ({ store }) => {
  try {
    const url = `/api/v1/OrderMagic/list`;
    // const url = `/api/v1/category/OrderMagic/list`;
    const { data } = await catalogApi.get(url, {
      params: {
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar la lista de ordenamientos magicos");
  }
};

const getListMagicalOrderingCollection = async ({ store }) => {
  try {
    // const url = `/api/v1/OrderMagic/list`;
    const url = `/api/v1/Collection/OrderMagic/list`;
    const { data } = await catalogApi.get(url, {
      params: {
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar la lista de ordenamientos magicos");
  }
};

const setSettingsMagicalOrderCategories = async ({ params }) => {
  try {
    const url = `/api/v1/OrderMagic/configuration`;
    // const url = `/api/v1/category/OrderMagic/configuration`;
    const { data } = await catalogApi.post(url, params);

    return data;
  } catch (error) {
    throw new Error(`No se ha guardado el ordenamiento magico ${params.name}`);
  }
};
const setCreateSettingsMagicalOrderCollection = async ({ params }) => {
  try {
    const url = `/api/v1/Collection/OrderMagic/configuration`;
    const { data } = await catalogApi.post(url, params);

    return data;
  } catch (error) {
    throw new Error(`No se ha guardado el ordenamiento magico ${params.name}`);
  }
};

const editMagicalOrderCategories = async ({ post }) => {
  try {
    const url = `/api/v1/OrderMagic/update`;
    // const url = `/api/v1/category/OrderMagic/update`;
    const { data } = await catalogApi.put(url, post);
    return data;
  } catch (error) {
    throw new Error(`No se ha podido editar el ordenamiento ${post.name}`);
  }
};
const editMagicalOrderCollection = async ({ post }) => {
  try {
    const url = `/api/v1/Collection/OrderMagic/update`;
    const { data } = await catalogApi.put(url, post);
    return data;
  } catch (error) {
    throw new Error(`No se ha podido editar el ordenamiento ${post.name}`);
  }
};

const deleteMagicalOrderCategories = async ({ idDelete }) => {
  try {
    const url = `/api/v1/OrderMagic/${idDelete}`;
    // const url = `/api/v1/category/OrderMagic/${idDelete}`;
    const { data } = await catalogApi.delete(url);
    return data.isSuccess;
  } catch (error) {
    throw new Error(
      `No se ha podido eliminar el ordenamiento magico ${idDelete}`
    );
  }
};
const deleteMagicalOrderCollection = async ({ idDelete }) => {
  try {
    const url = `/api/v1/Collection/OrderMagic/${idDelete}`;
    const { data } = await catalogApi.delete(url);
    return data.isSuccess;
  } catch (error) {
    throw new Error(
      `No se ha podido eliminar el ordenamiento magico ${idDelete}`
    );
  }
};

const runMagicalOrderingCategories = async ({ post: params }) => {
  try {
    const url = `/api/v1/OrderMagic/trigger`;
    // const url = `/api/v1/category/OrderMagic/trigger`;
    const data = await catalogApi.post(url, params);
    return data;
  } catch (error) {
    throw new Error("No se ha podido inicar el ordenamiento magico");
  }
};
const runMagicalOrderingCollection = async ({ post: params }) => {
  try {
    const url = `/api/v1/Collection/OrderMagic/trigger`;
    const data = await catalogApi.post(url, params);
    return data;
  } catch (error) {
    throw new Error("No se ha podido inicar el ordenamiento magico");
  }
};

export {
  getListMagicalOrderingCategories,
  getListMagicalOrderingCollection,
  setSettingsMagicalOrderCategories,
  setCreateSettingsMagicalOrderCollection,
  editMagicalOrderCategories,
  editMagicalOrderCollection,
  deleteMagicalOrderCategories,
  deleteMagicalOrderCollection,
  runMagicalOrderingCategories,
  runMagicalOrderingCollection,
};
