import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "../../atoms/Button";
import { Div } from "../../atoms/Div";
import { Span } from "../../atoms/Span";

export const Popup = () => {
  const { t } = useTranslation();
  const { modalPopupData, modalPopup } = useSelector((state) => state.utils);

  const handleClick = () => {
    modalPopupData.type === "collection"
      ? modalPopupData.action(modalPopupData.products)
      : modalPopupData.action();
  };

  return (
    <Div className="popUpOrder" data-visible={modalPopup}>
      <Div className="popUpOrder__content">
        {t("popup.txt1")}
        <Span className="popUpOrder__span">
          {modalPopupData?.products?.length}
        </Span>
        {t("popup.txt2")}
        <Button className="popUpOrder__gomass" onClick={handleClick}>
          {modalPopupData.type === "collection"
            ? "Agregar productos"
            : t("popup.button")}
        </Button>
      </Div>
    </Div>
  );
};
