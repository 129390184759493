import React from "react";
import { Link } from "react-router-dom";

export const Lin = ({
  children,
  className,
  path,
  disabled,
  state = {},
  target,
}) => {
  return (
    <Link
      to={path}
      className={className}
      state={state}
      disabled={disabled}
      target={target ? target : ""}
    >
      {children}
    </Link>
  );
};
