import { useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-toastify/dist/ReactToastify.css";
import { saveUser } from "./actions/auth";
import { startSaveStores } from "./actions/stores";
import { Div } from "./components/atoms/Div";
import { MainFooter } from "./components/organisms/mainFooter/mainFooter";
import { MainHeader } from "./components/organisms/mainheader/MainHeader";
import { AppModals } from "./components/organisms/modals/AppModals";
import Routes from "./routes";

export const App = ({ userInfo, keycloak }) => {
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    dispatch(saveUser(userInfo));
    const startSaveStore = async () => {
      dispatch(startSaveStores(userInfo.email));
    };
    startSaveStore();
  }, [dispatch, userInfo]);

  return (
    <Div className="app">
      {pathname !== "/login_new" && <MainHeader keycloak={keycloak} />}
      <AppModals />
      <Div className="app__main-content">
        <Routes />
      </Div>
      {pathname !== "/login_new" && <MainFooter />}
    </Div>
  );
};
