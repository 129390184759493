import Collapse from '@material-ui/core/Collapse';
import React, { useState } from 'react';
import { Div } from '../../atoms/Div';
import { SectionCollapse } from './SectionCollapse';
import { SectionPoint } from './SectionPoint';

export const SubSubTheme = ({ subsubtheme,t, idParents }) => {
    const [collapse, setCollapse] = useState(false)
    const [classestitle, setClassesTitle] = useState("subsubtheme__title")

    const toggleUserActions = () => {
        if (collapse) {
            setClassesTitle("subsubtheme__title")
            setCollapse(!collapse)
        } else {
            setClassesTitle("subsubtheme__title-open")
            setCollapse(!collapse)
        }
    }

    return (
        <Div className="subsubtheme">
            <Div className={classestitle} onClick={toggleUserActions}>
                {t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.title`)}
            </Div>
            <Collapse in={collapse}>
                <Div className="subsubtheme__description" dangerouslySetInnerHTML={{ __html: t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.description`) }}>
                </Div>
                {subsubtheme.video !== ""
                    ? <Div className="subsubtheme__video"><video width="357" height="200" controls >
                        <source src={subsubtheme.video} type="video/mp4" />
                    </video></Div>
                    : null
                }
                {subsubtheme.section.map((section, idx) =>
                    <Div key={`subsubtheme_${idx}`} >
                        {section.type === "collapse"
                            ? <SectionCollapse section={section} t={t} idParents={{...idParents, idSection: idx}}></SectionCollapse>
                            : null
                        }
                        {section.type === "point"
                            ? <SectionPoint section={section} t={t} idParents={{...idParents, idSection: idx}}></SectionPoint>
                            : null
                        }
                    </Div>
                )}
                <Div className="subsubtheme__description" dangerouslySetInnerHTML={{ __html: t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.complement`) }}>
                </Div>
                <Div className="subsubtheme__note">
                    {t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.note`)}
                </Div>
            </Collapse>
        </Div>

    )
}
