import { catalogApi } from "./config";

const getTopFiveProducts = async ({ store, categoryId }) => {
  try {
    const url = `/api/v1/Catalogue/topproductsbycategoryid`;
    const { data } = await catalogApi.get(url, {
      params: {
        store,
        categoryId,
        limit: 5,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar el top five de productos.");
  }
};

const loadCategoryperfonmance = async ({ store, categoryId }) => {
  try {
    const url = `/api/v1/Catalogue/categroyperformance`;
    const { data } = await catalogApi.get(url, {
      params: { store, categoryId },
    });
    return data;
  } catch (error) {
    throw new Error("No se a podido cargar los datos de perfonmance.");
  }
};

const getCategoryGraphic = async ({
  startDate,
  endDate,
  cats,
  catSales,
  store,
}) => {
  try {
    let url = "/api/v1/Catalogue/getCategoriesTableforGraphic";
    const { data } = await catalogApi.get(`${url}?${cats}`, {
      params: {
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.format("YYYY-MM-DD"),
        isQty: catSales,
        store,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error("No se ha podido cargar los datos de la grafica.");
  }
};

const getProductGraphic = async ({
  startDate,
  endDate,
  cats,
  catSales,
  store,
}) => {
  try {
    let url = "/api/v1/Catalogue/getProductsTableforGraphic";
    const { data } = await catalogApi.get(`${url}?${cats}`, {
      params: {
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.format("YYYY-MM-DD"),
        isQty: catSales,
        store,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error("No se ha podido cargar los datos de la grafica.");
  }
};

const getSalesByRange = async ({ StartDate, EndDate, store, bll }) => {
  try {
    let url = `/api/v1/Catalogue/getSalesByRange`;
    const { data } = await catalogApi.get(url, {
      params: {
        from: StartDate.format("YYYY-MM-DD"),
        to: EndDate.format("YYYY-MM-DD"),
        isQty: bll,
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar ventas por rango.");
  }
};

const getTotalSales = async ({ StartDate, EndDate, store }) => {
  let url = "/api/v1/Catalogue/getTotalSales";
  try {
    const { data } = await catalogApi.get(url, {
      params: {
        from: StartDate.format("YYYY-MM-DD"),
        to: EndDate.format("YYYY-MM-DD"),
        store,
      },
    });

    return data.data;
  } catch (error) {
    throw new Error("No se ha cargado los datos de ventas totales");
  }
};

export {
  getTopFiveProducts,
  loadCategoryperfonmance,
  getCategoryGraphic,
  getSalesByRange,
  getProductGraphic,
  getTotalSales,
};
