const initialState = {
  showCriteria: false,
  showModalChangeSmartMan: false,
  showMenuAddColumns: false,
  search: {
    searchNameProduct: 0,
    textNameProduct: "",
    searching: 1,
  },
  criteriaOrder: [
    {
      id: 0,
      column: "smartmanScore",
      sort: "DESC",
      arrowColumn: false,
      arrowSort: false,
      cssclass: "list-options-modal-0",
    },
    {
      id: 1,
      column: null,
      sort: null,
      arrowColumn: false,
      arrowSort: false,
      cssclass: "list-options-modal-1",
    },
    {
      id: 2,
      column: null,
      sort: null,
      arrowColumn: false,
      arrowSort: false,
      cssclass: "list-options-modal-2",
    },
    {
      id: 3,
      column: null,
      sort: null,
      arrowColumn: false,
      arrowSort: false,
      cssclass: "list-options-modal-3",
    },
  ],
  optionsOrderMassive: [
    {
      id: 0,
      status: false,
      value: 0,
    },
    {
      id: 1,
      status: false,
      value: 0,
    },
    {
      id: 2,
      status: false,
      value: 0,
    },
  ],
  optionsOrderPosition: [
    {
      id: 0,
      status: false,
      value: 1,
    },
    {
      id: 1,
      status: false,
      value: 1,
    },
  ],
  generalUi: {
    modals: false,
    typeTable: "",
    storage: "",
    endpoint: "",
    search: false,
  },
};

const sortByCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_CRITERIA":
      return {
        ...state,
        showCriteria: action.payload,
      };
    case "SET_SHOW_MODAL_CHANGE_SMARTMAN":
      return {
        ...state,
        showModalChangeSmartMan: action.payload,
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: { ...state.search, ...action.payload },
      };
    case "SET_CRITERIA_ORDER":
      return {
        ...state,
        criteriaOrder: [...action.payload],
      };
    case "SET_OPTIONS_ORDER_MASSIVE":
      return {
        ...state,
        optionsOrderMassive: [...state.optionsOrderMassive],
      };
    case "SET_OPTIONS_ORDER_POSITION":
      return {
        ...state,
        optionsOrderPosition: [...state.optionsOrderMassive],
      };
    case "SET_GENERAL_UI":
      return {
        ...state,
        generalUi: { ...state.generalUi, ...action.payload },
      };
    case "SHOW_MENU_ADD_COLUMNS":
      return {
        ...state,
        showMenuAddColumns: action.payload,
      };
    default:
      return state;
  }
};

export { sortByCategoryReducer };
