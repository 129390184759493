import { types } from "../types/types";

// as {createCollection} object in store
let initialState = {
  nameCollection: "",
  collection: {
    id: {
      name: "",
      store: "",
    },
    nameCollection: "",
    description: "",
    idVtex: "",
    active: true,
    highlight: true,
    searchable: true,
    startDate: "",
    endDate: "",
    vtexId: "",
    inVtex: false,
  },
  groupEdit: {},
  groups: [],
  groupSelected: [],
  groupProducts: [],
  products: [],
};

export const createCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.collectionName:
      return {
        ...state,
        nameCollection: action.payload,
        collection: {
          ...state.collection,
          id: {
            ...state.collection.id,
            name: action.payload,
          },
        },
      };
    case types.collectionGroups:
      return {
        ...state,
        groups: action.payload,
      };
    case types.collectionGroupEdit:
      return {
        ...state,
        groupEdit: action.payload,
      };
    case types.collectionGroupSelected:
      return {
        ...state,
        groupSelected: action.payload,
      };
    case types.collectionProducts:
      return {
        ...state,
        products: action.payload,
      };
    case types.collectionUpdate:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.payload,
        },
      };
    case types.groupProducts:
      return {
        ...state,
        groupProducts: action.payload,
      };

    default:
      return state;
  }
};
