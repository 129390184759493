import { toast } from "react-toastify";
import { getPlanByUser } from "../services";
import { types } from "../types/types";

export const startSavePlanActual = (email, store) => {
  return async (dispatch) => {
    try {
      const data = await getPlanByUser({ email, store });
      data[0]?.userMembershipId &&
        dispatch(savePlanActual(data[0]?.userMembershipId));
    } catch (error) {
      toast(`${error}`, {
        autoClose: 5000,
        type: "error",
        position: "bottom-left",
        hideProgressBar: true,
      });
    }
  };
};

export const savePlanActual = ({ membershipName, membershipId }) => ({
  type: types.saveplanactual,
  payload: {
    planactual: membershipName,
    idPLan: membershipId,
  },
});

export const saveUser = (credentials) => ({
  type: types.login,
  payload: {
    name: credentials.name,
    email: credentials.email,
    active: true,
  },
});
