import Slide from '@material-ui/core/Slide';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTheme } from "./SubTheme";

export const Theme = ({theme, idTheme}) => {
    const [slide, setSlide] = useState(false)
    const {t} = useTranslation()
    
    const handleSlide = () =>{setSlide(!slide)}

    return (
        <div className="theme">
            <div className="theme__button-title" onClick={handleSlide}>
                {t(`quickdemo.${idTheme}.maintheme`)}
            </div>
            <Slide direction="left" in={slide} className="theme__container" mountOnEnter unmountOnExit>
                <div>
                    <div className="theme__back-button" onClick={handleSlide}>
                        volver
                    </div>
                    <div className="theme__body">
                        <div className="theme__title">
                            {t(`quickdemo.${idTheme}.maintheme`)}
                        </div>
                        {theme.subthemes.map((subtheme, idx) =>
                            <SubTheme key={`__${subtheme.title}`} t={t} idParents={{idTheme, idSubTheme:idx}} subtheme={subtheme}></SubTheme>
                        )}
                    </div>
                </div>
            </Slide>
        </div>

    )
}
