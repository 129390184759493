import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Div } from "../../atoms/Div";

export const MainFooter = memo(function MainFooter() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <Div className="footer__content">
        <section className="footer__section">
          <Link className="mainheader__smartman-logo footer__logo" to="/home" />
        </section>
        <section className="footer__section">
          <article className="footer__article">
            <h2 className="footer__article__title">
              {t("footer.smartman.title")}
            </h2>
            <a
              className="footer__article__link"
              href="https://smartman.ai/contacto"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.smartman.link_1")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Tour"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.smartman.link_2")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Casos"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.smartman.link_3")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Soporte"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.smartman.link_4")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Precios"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.smartman.link_5")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Actualizaciones"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.smartman.link_6")}
            </a>
          </article>
          <article className="footer__article">
            <h2 className="footer__article__title">
              {t("footer.resourses.title")}
            </h2>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Blog"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.resourses.link_1")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Guias"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.resourses.link_2")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Casos"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.resourses.link_3")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Prueba"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.resourses.link_4")}
            </a>
          </article>
          <article className="footer__article">
            <h2 className="footer__article__title">
              {t("footer.legal.title")}
            </h2>
            <a
              className="footer__article__link"
              href="https://smartman.ai/terminos"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.legal.link_1")}
            </a>
            <a
              className="footer__article__link"
              href="https://www.smartman.ai/privacidad?_ga=2.269191160.886129849.1637949520-1144075585.1637949520"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.legal.link_2")}
            </a>
            <a
              className="footer__article__link"
              href="https://www.smartman.ai/uso_aceptable?_ga=2.269191160.886129849.1637949520-1144075585.1637949520"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.legal.link_3")}
            </a>
            <a
              className="footer__article__link footer__article__link--disabled"
              href="Política"
              rel="noreferrer"
              target="_blank"
            >
              {t("footer.legal.link_4")}
            </a>
          </article>
        </section>
      </Div>
    </footer>
  );
});

