import { lazy } from "react";

const TypeOfOrder = lazy(() =>
  import("../components/organisms/order/TypeOfOrder")
);
const PlanSection = lazy(() =>
  import("../components/organisms/PlanSection/PlanSection")
);
const CategorySort = lazy(() => import("../components/pages/CategorySort"));
const CollectionsSort = lazy(() =>
  import("../components/pages/CollectionsSort")
);
const Configuration = lazy(() => import("../components/pages/Configuration"));
const CreateCollection = lazy(() =>
  import("../components/pages/CreateCollection")
);
const EditCollection = lazy(() => import("../components/pages/EditCollection"));
const CreateEditGroup = lazy(() =>
  import("../components/pages/CreateEditGroup")
);
const ListGroups = lazy(() => import("../components/pages/ListGroups"));
const ListSmartmanCollections = lazy(() =>
  import("../components/pages/ListSmartmanCollections")
);
const CollectionAddGroups = lazy(() =>
  import("../components/pages/CollectionAddGroups")
);
const Home = lazy(() => import("../components/pages/Home"));
const SmartmanAdminDashboard = lazy(() =>
  import("../components/pages/SmartmanAdminDashboard")
);
const IndexAdmin = lazy(() => import("../components/pages/IndexAdmin"));
const Login = lazy(() => import("../components/pages/Login"));
const MagicalOrderingCategories = lazy(() =>
  import("../components/pages/MagicalOrderingCategories")
);
const MagicalOrderingCollections = lazy(() =>
  import("../components/pages/MagicalOrderingCollections")
);
const MyAccount = lazy(() => import("../components/pages/MyAccount"));
const NewStore = lazy(() => import("../components/pages/NewStore"));
const OrderByCategory = lazy(() =>
  import("../components/pages/OrderByCategory")
);
const OrderByCollection = lazy(() =>
  import("../components/pages/OrderByCollection")
);
const Reports = lazy(() => import("../components/pages/Reports"));
const SelectedPlanWizard = lazy(() =>
  import("../components/pages/SelectedPlanWizard")
);
const SelectOrder = lazy(() => import("../components/pages/SelectOrder"));
const Synchronize = lazy(() => import("../components/pages/Synchronize"));
const Category = lazy(() => import("../components/pages/Category"));
const Collection = lazy(() => import("../components/pages/Collection"));

const routes = [
  { path: "/home", component: Home },
  { path: "/dashboard/admin", component: SmartmanAdminDashboard },
  { path: "/category", component: Category },
  { path: "/collection", component: Collection },
  { path: "/reports", component: Reports },
  { path: "/configuration", component: Configuration },
  { path: "/user-profile", component: MyAccount },
  { path: "/plans", component: PlanSection },
  {
    path: "/plans/:recurrencePeriodNameSelected/:planNameSelected",
    component: SelectedPlanWizard,
  },
  { path: "/category-sort", component: CategorySort },
  { path: "/collections-sort", component: CollectionsSort },
  { path: "/synchronize", component: Synchronize },
  { path: "/order-by-category", component: OrderByCategory },
  { path: "/order-by-collection", component: OrderByCollection },
  { path: "/create-smartman-collection", component: CreateCollection },
  {
    path: "/smartman-collections/edit/:nameCollection",
    component: EditCollection,
  },
  {
    path: "/smartman-collections/edit/:nameCollection/add-groups",
    component: CollectionAddGroups,
  },

  { path: "/groups/list", component: ListGroups },
  { path: "/groups/create", component: CreateEditGroup },
  { path: "/groups/edit", component: CreateEditGroup },
  { path: "/smartman-collections", component: ListSmartmanCollections },
  { path: "/new-store", component: NewStore },
  { path: "/index-admin", component: IndexAdmin },
  { path: "/magic-sort-categories", component: MagicalOrderingCategories },
  {
    path: "/magic-sort-collections",
    component: MagicalOrderingCollections,
  },
  { path: "/order-prod", component: TypeOfOrder },
  { path: "/manual-sort", component: SelectOrder },
  { path: "/login_new", component: Login },
];

export { routes };
