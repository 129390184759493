import { catalogApi } from "./config";

const getListOrdersToIndex = async ({ email, store }) => {
  try {
    let url = `/api/v1/Catalogue/getCategoryToIndex`;

    const { data } = await catalogApi.get(url, {
      params: { username: email, store },
    });
    return data;
  } catch (error) {
    throw new Error("No se a podido cargar la lista de ordenamientos");
  }
};

const indexProgram = async ({ initDate, email, store, indexId }) => {
  try {
    let url = `/api/v1/Catalogue/setIndexDate`;
    const data = await catalogApi.post(url, null, {
      params: { initDate, username: email, store, indexId },
    });
    return data;
  } catch (error) {
    throw new Error("No se a podido programar la indezación");
  }
};

const indexOrder = async ({ categoryId, store, username }) => {
  try {
    let url = `/api/v1/Catalogue/indexCategory?categoryId=${categoryId}`;

    const { data } = await catalogApi.post(url, null, {
      params: {
        store,
        username,
      },
    });
    return data;
  } catch (error) {}
};

const deletOrders = async ({ store, username, indexDate, categoryId }) => {
  try {
    let url = "/api/v1/Catalogue/cancelIndex";

    const { data } = await catalogApi.delete(url, {
      params: {
        store,
        username,
        indexDate,
        categoryId,
      },
    });

    return data;
  } catch (error) {
    throw new Error("No se ha podido eliminar el ordenamiento.");
  }
};

export { getListOrdersToIndex, indexProgram, indexOrder, deletOrders };
