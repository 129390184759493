import { userApi } from "./config";

const getPlanByUser = async ({ email, store }) => {
  try {
    let url = `/api/v1/user/getAllMembershipByUsernameAndStore`;
    let {
      data: { data },
    } = await userApi(url, {
      params: {
        username: email,
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export { getPlanByUser };
