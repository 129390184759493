import React from "react";
import { NavLink } from "react-router-dom";

export const NavLin = ({ children, className, path, activeClassName,  onClick}) => {
  return (
    <NavLink to={path} className={className} activeClassName={activeClassName}  onClick={onClick}>
      {children}
    </NavLink>
  );
};
