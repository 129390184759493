import React from "react";
import { Div } from "../../atoms/Div";
import { NavLin } from "../../atoms/NavLin";
import { Span } from "../../atoms/Span";


export const ItemSideBar = ({ value, text, path, actual , changeRouteActual}) => {


  return (
    <Div className={"itemsidebar"}>
      <Div className={"itemsidebar__hover"}></Div>
      {path === actual ? <Div className={"itemsidebar"}></Div> : null}
      <NavLin
        path={path}
        className={"itemsidebar__link"}
        onClick={() => changeRouteActual(path)}
        
      >
        {path === actual ? (
          <Div className={`itemsidebar__img-${value}-active`}></Div>
        ) : (
          <Div className={`itemsidebar__img-${value}`}></Div>
        )}
        {path === actual ? (
          <Span className="itemsidebar__linkactive">{text}</Span>
        ) : (
          <Span className="itemsidebar__links">{text}</Span>
        )}
      
      </NavLin>
    </Div>
  );
};
