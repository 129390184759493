import { MenuItem, Select } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { startSavePlanActual } from "../../../actions/auth";
import { get, set } from "../../../helpers/sessionStorage";
import iconEn from "../../../images/header/en.svg";
import iconEs from "../../../images/header/es.svg";
import iconPlan from "../../../images/header/icon_plan.svg";
import iconPt from "../../../images/header/pt.svg";
import { Button } from "../../atoms/Button";
import { Div } from "../../atoms/Div";
import { Image } from "../../atoms/Image";
import { BurgerMenu } from "../../molecules/burgerMenu/BurgerMenu";
import StoreSelect from "../../molecules/mainheader/StoreSelect";
import { Popup } from "../../molecules/orderbycategory/Popup";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";
import { QuickDemo } from "../quickdemo/QuickDemo";
const LANGS = ["es", "en", "pt"];

export const MainHeader = memo(function MainHeader({ keycloak }) {
  const dispatch = useDispatch();
  const stories = useSelector((state) => state.stores, shallowEqual);
  const auth = useSelector((state) => state.auth, shallowEqual);
  const { t, i18n } = useTranslation();
  const [location, setlocation] = useState(() => {
    let language = LANGS.filter((lang) => i18n?.location?.search(lang) > -1);
    return language.length > 0 ? language[0] : "es";
  });

  const [visible, setVisible] = useState(false);

  const toggleUserActions = () => {
    setVisible(!visible);
  };

  const handleChange = ({ target }) => {
    setlocation(target.value);
    set("language", target.value);
  };

  useEffect(() => {
    if (stories.actualstore.store !== "Selecciona una tienda") {
      dispatch(startSavePlanActual(auth.email, stories.actualstore.store));
    }
  }, [stories.actualstore.store, auth.email, dispatch]);

  useEffect(() => {
    i18n.changeLanguage(location);
  }, [location, i18n]);

  useEffect(() => {
    const language_selected = get("language");
    language_selected && setlocation(language_selected);
  }, []);

  return (
    <>
      <Div className="mainheader">
        <Div className="mainheader__left-side">
          <Div className="burgerMenu">
            <BurgerMenu className="burgerMenu" />
          </Div>
          <Link className="mainheader__smartman-logo" to="/home" />
          <StoreSelect />
          <Div className="mainheader__plan">
            <Image source={iconPlan} className="mainheader__plan__icon" />
            Plan {auth.planactual.toLowerCase()}
          </Div>
        </Div>
        <Div className="mainheader__right-side">
          <Link className="mainheader__transparent-button" to="/user-profile">
            {t("header.button")}
          </Link>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            IconComponent={ExpandMore}
            value={location}
            onChange={handleChange}
            autoWidth
            label="Age"
            variant="standard"
          >
            <MenuItem value="es">
              <Image className="flag__location" source={iconEs} />
            </MenuItem>
            <MenuItem value="en">
              <Image className="flag__location" source={iconEn} />
            </MenuItem>
            <MenuItem value="pt">
              <Image className="flag__location" source={iconPt} />
            </MenuItem>
          </Select>
          <Button
            className="mainheader__quickdemo-button"
            onClick={toggleUserActions}
          />
          <QuickDemo
            visible={visible}
            toggleUserActions={toggleUserActions}
          ></QuickDemo>
          <DropDownMenu keycloak={keycloak} />
        </Div>
      </Div>
      <Popup />
    </>
  );
});
