import { get } from "../helpers/sessionStorage";
import { types } from "../types/types";

let initialState = {
  storeget: false,
  stores: [],
  actualstore: { store: "Selecciona una tienda" },
  indexstore: null,
};

const sessionState = get("saveStores") || {};

initialState = { ...initialState, ...sessionState };

export const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.savestores:
      return {
        ...state,
        storeget: true,
        stores: action.payload,
      };

    case types.updateappstore:
      return {
        ...state,
        actualstore: {...action.payload.actualstore, io: false},
        indexstore: action.payload.indexstore,
      };

    default:
      return state;
  }
};
