import Collapse from "@material-ui/core/Collapse";
import React, { useState } from "react";
import { Div } from "../../atoms/Div";

export const SectionCollapse = ({ section, idParents, t }) => {
  const [collapse, setCollapse] = useState(false);
  const [classestitle, setClassesTitle] = useState("section-collapse__title");

  const toggleUserActions = () => {
    if (collapse) {
      setClassesTitle("section-collapse__title");
      setCollapse(!collapse);
    } else {
      setClassesTitle("section-collapse__title-open");
      setCollapse(!collapse);
    }
  };
  return (
    <Div className="section-collapse">
      <Div className={classestitle} onClick={(ev) => toggleUserActions()}>
        {t(
          `quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.section.${idParents.idSection}.title`
        )}
      </Div>
      <Collapse in={collapse}>
        <Div className="section-collapse__text">
          {t(
            `quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.section.${idParents.idSection}.text`
          )}
        </Div>
        {section.subsection.map((subsection, index) => (
          <Div key={`subsection_${index}`}>
            {subsection.style === "steps" ? (
              <ol>
                {subsection.content.map((content, idx) => (
                  <li
                    key={`subsection_content_${idx}`}
                    className="section-collapse__steps"
                    value={content.value}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.section.${idParents.idSection}.subsection.${index}.content.${idx}.text`
                      ),
                    }}
                  ></li>
                ))}
              </ol>
            ) : null}
          </Div>
        ))}
      </Collapse>
    </Div>
  );
};
