import { update } from "../helpers/sessionStorage";
import { catalogApi } from "./config";

const syncProductsByCollectionId2 = async ({
  username,
  store,
  collectionId,
}) => {
  try {
    const url = `/api/v1/sync/products-collection-by-id?username=${username}&store=${store}&idCollection=${collectionId}`;
    const { data } = await catalogApi.post(url);

    return data;
  } catch (error) {
    throw new Error("No se ha podido sincronizar la colección.");
  }
};

const getStoreBrands = async ({ store, username }) => {
  try {
    const url = `/api/v1/Catalogue/getbrandsVtex?store=${store}&username=${username}`;
    const { data } = await catalogApi.get(url);
    return data.data;
  } catch (error) {
    throw new Error("No se ha podido cargar el listado de marcas.");
  }
};

const getProductsByCollection = async ({
  store,
  id_collection,
  email,
  order,
}) => {
  try {
    const {
      data: { data, isSuccess },
    } = await catalogApi.post(
      `/api/v1/Collection/sorttable/page/1/100000`,
      order,
      {
        params: {
          store,
          collectionId: id_collection,
          username: email,
        },
      }
    );
    if (!isSuccess) {
      return { error: "No se encuentran productos para la coleccion" };
    } else {
      return data.content;
    }
  } catch (error) {
    throw new Error("No se encuentran productos para la coleccion");
  }
};

const getAllCollectionsVtex = async (params) => {
  try {
    const { data } = await catalogApi.get("/api/v1/Collection/all", {
      params,
    });
    return { data };
  } catch (error) {
    throw new Error("No se ha podido cargar el listado de colecciones.");
  }
};

const getNewCategoryTree = async ({ store }) => {
  try {
    const {
      data: { data },
    } = await catalogApi.get(`/api/v1/Catalogue/getNewCategoryTree`, {
      params: {
        store,
      },
    });
    update("dataCategory", { categoryTree: data });

    return data;
  } catch (error) {
    throw new Error(`No se ha podido cargar el arbol de categorias ${store}.`);
  }
};

const getCategories = async ({ email, store }) => {
  try {
    const url = `/api/v1/VtexCataogue/categorytree/{username}`;
    const { data } = await catalogApi.get(url, {
      params: {
        username: email,
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar el arbol de categorias.");
  }
};

const getProductByCategory = async ({ store, categoryId, username, order }) => {
  try {
    const url = `/api/v1/Catalogue/sorttable/page/1/100000`;
    const { data } = await catalogApi.post(url, order, {
      params: {
        store,
        categoryId,
        username,
      },
    });
    return data.data.content;
  } catch (error) {
    throw new Error("No se ha podido cargar el listado de productos.");
  }
};

const sincronizeCollections = async ({ store, username }) => {
  try {
    const url = "/api/v1/Collection/sync";
    await catalogApi.get(url, {
      params: {
        store,
        username,
      },
    });
    return { data: "Sincronizado" };
  } catch (error) {
    throw new Error("No se han podido sincronizar las colecciones");
  }
};

const saveSortable = async ({ products, email }) => {
  try {
    const url = `/api/v1/Catalogue/saveSortTable?username=${email}`;
    const { data } = await catalogApi.post(url, products);

    return data;
  } catch (error) {
    throw new Error("No se ha podido guardar el ordenamiento.");
  }
};

const syncProductsByCollectionId = async ({
  username,
  store,
  collectionId,
}) => {
  try {
    const url = `/api/v1/sync/products-collection-by-id?username=${username}&store=${store}&idCollection=${collectionId}`;
    const { data } = await catalogApi.post(url);

    return data;
  } catch (error) {
    throw new Error("No se ha podido sincronizar la colección.");
  }
};

export {
  syncProductsByCollectionId2,
  getAllCollectionsVtex,
  getCategories,
  getNewCategoryTree,
  getProductByCategory,
  getProductsByCollection,
  getStoreBrands,
  saveSortable,
  sincronizeCollections,
  syncProductsByCollectionId,
};
