import { get } from "../helpers/sessionStorage";
import { types } from "../types/types";

let initialState = {
  listCollections: [],
  collectionLoading: false,
  collectionById: null,
  collectionError: null,
  collectionSave: null,
  productsCollectionLoading: 'idle',
  productsCollectionError: false,
  productsCollection: []
};

const sessionState = get("dataCollections") || {};
initialState = { ...initialState, ...sessionState };

export const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.collectionList:
      return {
        ...state,
        listCollections: action.payload,
      };
    case types.collectionLoading:
      return {
        ...state,
        collectionLoading: action.payload
      };
    case types.collectionError:
      return {
        ...state,
        collectionError: action.payload
      };
    case types.collectionById:
      return {
        ...state,
        collectionById: action.payload
      };
    case types.collectionSave:
      return {
        ...state, 
        collectionSave: action.payload
      };
    case types.productCollection:
      return {
        ...state, 
        productsCollection: action.payload
      };
    case types.productCollectionError:
      return {
        ...state, 
        productsCollectionError: action.payload
      };
    case types.productCollectionLoading:
      return {
        ...state, 
        productsCollectionLoading: action.payload
      };
    default:
      return state;
  }
};
