import { get } from "../helpers/sessionStorage";
import { types } from "../types/types";

let initialState = {
  idcategoria: "",
  namecategory: "",
  routebefore: "",
  categoryTree: null,
  categoryTreeLoading: "idle",
  categoryTreeError: null,
};
const sessionState = get("dataCategory") || {};

initialState = { ...initialState, ...sessionState };

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.saveCategories:
      return {
        ...state,
        idcategoria: action.payload.idcategoria,
        namecategory: action.payload.namecategory,
        routebefore: action.payload.routebefore,
      };
    case types.categories:
      return {
        ...state,
        categoryTree: action.payload,
      };
    case types.categoriesLoading:
      return {
        ...state,
        categoryTreeLoading: action.payload,
      };
    case types.categoriesError:
      return {
        ...state,
        categoryTreeError: action.payload,
      };

    default:
      return state;
  }
};
