import Keycloak from "keycloak-js";
import React from "react";
import ReactDOM from "react-dom";
import { SmartmanApp } from "./SmartmanApp";
import "./styles/main.scss";

let keycloak = Keycloak("/keycloak.json");

keycloak
  .init({ onLoad: "login-required" })
  .then((authenticated) => {
    if (!authenticated) {
      window.location.reload();
    }

    keycloak.loadUserInfo().then((userInfo) => {
      ReactDOM.render(
        <SmartmanApp userInfo={userInfo} keycloak={keycloak} />,
        document.getElementById("root")
      );
    });

    //store authentication tokens in sessionStorage for usage in app
    sessionStorage.setItem("authentication", keycloak.token);
    sessionStorage.setItem("refreshToken", keycloak.refreshToken);
    setTimeout(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }, 120000);
  })
  .catch(() => {
    throw new Error("Authenticated Failed");
  });
