import { realTimeApi } from "./config";

const getInfoRealTime = async ({ store }) => {
  try {
    let url = "/api/v1/report/time/real/totalValuesOrder";
    const { data } = await realTimeApi.get(url, {
      params: {
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar la información de tiempo real");
  }
};

const getChartInfoRealTime = async ({ store }) => {
  try {
    let url = "/api/v1/report/time/real/ordersByRangeHourByStore";

    const { data } = await realTimeApi.get(url, {
      params: {
        store,
      },
    });

    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar los datos de la grafica");
  }
};

export { getInfoRealTime, getChartInfoRealTime };
