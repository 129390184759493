import React from "react";

export const Button = ({
  children,
  onClick,
  className,
  disabled,
  pseudoElementText,
  type,
  icon,
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      data-type={type}
      data-icon={icon}
      data-pseudo-element-text={pseudoElementText}
    >
      {children}
    </button>
  );
};
