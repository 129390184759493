export const types = {
  login: "AUTH saveUser",
  logout: "AUTH deleteUser",
  saveplanactual: "AUTH saveplanactual",

  storeselected: "STORES storeselected",
  savestores: "STORES savestores",
  updateappstore: "STORES updatestore",

  updateModalStatus: "STORES updateModalStatus",

  saveCategories: "CATEGORIES savecategories",
  categories: "CATEGORIES categories",
  categoriesLoading: "CATEGORIES categoriesLoading",
  categoriesError: "CATEGORIES categoriesError",
  saveCategoriesToChart: "CATEGORIESTOCHART saveCategoriesToChart",
  clearCategoriesToChart: "CATEGORIESTOCHART clearCategoriesToChart",

  collectionList: "COLLECTIONS collectionList",
  collectionLoading: "COLLECTIONS collectionLoading",
  collectionError: "COLLECTIONS collectionError",
  collectionById: "COLLECTIONS collectionById",
  collectionSave: "COLLECTIONS collectionSave",
  productCollection: "COLLECTIONS productCollection",
  productCollectionLoading: "COLLECTIONS productCollectionLoading",
  productCollectionError: "COLLECTIONS productCollectionError",

  collectionName: "COLLECTIONS collectionName",
  collectionGroups: "COLLECTIONS collectionGroups",
  collectionGroupEdit: "COLLECTIONS collectionGroupEdit",
  collectionGroupSelected: "COLLECTIONS collectionGroupSelected",
  collectionProducts: "COLLECTIONS collectionProducts",
  collectionUpdate: "COLLECTIONS collectionUpdate",
  groupProducts: "COLLECTIONS groupProducts",

  utils: "UTILS utils",
  utilsPopup: "UTILS popup",
  utilsPopupData: "UTILS popup data",

  toolBarIsVisible: "TOOLBAR isVisible",
  toolBarIsFixedMode: "TOOLBAR isFixedMode",
  toolBarMode: "TOOLBAR Mode",
  toolBarItemsList: "TOOLBAR itemsList",
  toolBarOriginalList: "TOOLBAR originalList",
  toolBarItemsFixed: "TOOLBAR itemsFixed",
  toolBarDataType: "TOOLBAR dataType",
  toolBarCanChange: "TOOLBAR canChange",
  toolBarValueFixed: "TOOLBAR valueFixed",

  tableSort: "TABLESORT tableSort",
  tableSortTableUIManualMode: "TABLESORT TableUIManualMode",
  tableSortShowMenuAddColumns: "TABLESORT ShowMenuAddColumns",
  tableSortTableDataConfig: "TABLESORT TableDataConfig",
  tableSortFilterKeys: "TABLESORT FilterKeys",
  tableSortTableInfoColumns: "TABLESORT TableInfoColumns",
};
