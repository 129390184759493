import { types } from "../types/types";

const initialState = {
    modalCategorySelected: false,
    modalPopup : false,
    modalPopupData: {}
}

export const utilsReducer = ( state = initialState, action) => {
    switch (action.type) {
        case types.utils:
          return {
            ...state,
            modalCategorySelected: action.payload,
          };
        case types.utilsPopup:
          return {
            ...state,
            modalPopup: action.payload,
          };
        case types.utilsPopupData:
          return {
            ...state,
            modalPopupData: action.payload,
          };
        default:
          return state;
      }
}