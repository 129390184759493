import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const options = {
  order: ["navigator", "htmlTag"],
};
const { REACT_APP_VERSION } = process.env;

i18n
  .use(Backend)
  .use(i18nextPlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["pt", "es", "en"],
    interpolation: {
      escapeValue: false,
    },
    detection: options,
    fallbackLng: "es",
    react: {
      bindI18n: "languageChanged",
      transKeepBasicHtmlNodesFor: ["a"],
      useSuspense: true,
    },
    defaultTransParent: "div",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
    transWrapTextNodes: "",
    load: "languageOnly",
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          prefix: `i18next_smc_${REACT_APP_VERSION}_`,
          expirationTime: 10 * 1000,
          store: typeof window !== "undefined" ? window.localStorage : null,
        },
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
      ],
    },
  });

export default i18n;
