import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "../../atoms/Button";
import { Div } from "../../atoms/Div";
import { Label } from "../../atoms/Label";

export const BurgerMenu = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const goTo = (a) => {
    history.push(a);
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Div className="burgerMenu">
              <Div>
                <Div
                  className="burgerMenu__burgerIcon"
                  variant="contained"
                  color="#1996FC"
                  {...bindTrigger(popupState)}
                />
              </Div>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={popupState.close}>
                  <Button className="mainheader__iconoReportes-button" />
                  <Button
                    className="category-report-header__lite-button"
                    onClick={() => goTo("/reports")}
                  >
                    {t("menu.reports")}
                  </Button>
                </MenuItem>
                <MenuItem onClick={popupState.close}>
                  <Button className="mainheader__orderProducts-button" />
                  <Button
                    className="category-report-header__lite-button"
                    onClick={() => goTo("/category")}
                  >
                    {t("menu.categories")}
                  </Button>
                </MenuItem>
                <MenuItem onClick={popupState.close}>
                  <Button className="mainheader__orderCollection-button" />
                  <Button
                    className="category-report-header__lite-button"
                    onClick={() => goTo("/collection")}
                  >
                    {t("menu.collections")}
                  </Button>
                </MenuItem>
                <MenuItem onClick={popupState.close}>
                  <Button className="mainheader__bellIcon-button" />
                  <Button
                    className="category-report-header__lite-button"
                    onClick={(ev) => goTo("/home")}
                  >
                    {t("menu.alerts")}
                    <Label className="main-report-header__coming-soon">
                      {t("menu.coming_soon")}
                    </Label>
                  </Button>
                </MenuItem>
              </Menu>
            </Div>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
};
