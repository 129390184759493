import { types } from "../types/types";

const initialState = {
  name: "",
  email: "",
  active: false,
  planactual: "",
  idPLan: 0,
};

export const authReducer = ( state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        active: action.payload.active,
      };
    case types.logout:
      return state;
    case types.saveplanactual:
      return {
        ...state,
        planactual: action.payload.planactual,
        idPLan: action.payload.idPLan
      };
    default:
      return state;
  }
};
