import { catalogApi } from "./config";

const synchronizeCatalog = async ({ categoryString, store, username }) => {
  try {
    const url = `/api/v1/VtexCataogue/actualizarProductos?${categoryString}`;
    const response = await catalogApi.post(url, null, {
      params: {
        store,
        username,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`No se ha podido sincronizar catalogo ${store}`);
  }
};

const synchronizeCatalogAll = async ({ categoryString, store, username }) => {
  try {
    const url = `/api/v1/sync/all?${categoryString}`;
    const response = await catalogApi.post(url, null, {
      params: {
        store,
        username,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`No se ha podido sincronizar catalogo ${store}`);
  }
};

const syncCollectionById = async ({ idCollection, store, username }) => {
  try {
    // api/v1/Collection/sync-by-id
    const url = `/api/v1/Collection/sync-by-id?store=${store}&username=${username}&idCollection=${idCollection}`;
    // const url = `/api/v1/Collection/sync-by-id?idCollection=${idCollection}&store=${store}&username=${username}`;
    const response = await catalogApi.get(url);
    return response;
  } catch (error) {
    throw new Error(`No se ha podido sincronizar la colección ${store}`);
  }
};

export { synchronizeCatalog, synchronizeCatalogAll, syncCollectionById };
