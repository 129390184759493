import React from 'react'
import { Div } from '../../atoms/Div'
import { SubSubTheme } from './SubSubTheme'

export const SubTheme = ({subtheme, t, idParents}) => {

    return (
        <Div className="subtheme">
            {subtheme.withvideo === true
                ?
                <video width="372" height="209" controls >
                    <source src={subtheme.video} type="video/mp4" />
                </video>
                : null
            }
            <Div className="subtheme__title">
                {t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.title`)}
            </Div>
            <Div className="subtheme__description" dangerouslySetInnerHTML={{ __html: t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.description`)}}>
            </Div>
            <Div>
                {subtheme.subsubtheme.map((subsubtheme, idx) =>
                    <SubSubTheme key={subsubtheme.title} t={t} idParents={{...idParents, idSubsubTheme: idx}}  subsubtheme={subsubtheme}></SubSubTheme>
                )}
            </Div>
            <Div className="subtheme__note">
                {t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.note`)}
            </Div>
        </Div>

    )
}
