import axios from "axios";
import { get as getStore } from "../helpers/sessionStorage";

let store = getStore("saveStores");

window.addEventListener("storage", () => {
  store = getStore("saveStores");
});

const catalogApi = axios.create({
  baseURL: process.env.REACT_APP_API_CATALOGUE,
  // baseURL: "http://localhost:8038",
  headers: {
    Authorization: process.env.REACT_APP_TOKEN,
    "X-TenantID": store?.actualstore?.store || "smartman",
  },
});
const collectionApi = axios.create({
  baseURL: process.env.REACT_APP_API_COLLECTION,
  // baseURL: "http://localhost:8038",
  headers: {
    Authorization: process.env.REACT_APP_TOKEN,
    "X-TenantID": store?.actualstore?.store || "smartman",
  },
});

const userApi = axios.create({
  // baseURL: "http://localhost:8083",
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: process.env.REACT_APP_TOKEN,
  },
});

const realTimeApi = axios.create({
  baseURL: process.env.REACT_APP_REAL_TIME,
  headers: {
    Authorization: process.env.REACT_APP_TOKEN,
  },
});

const paymentApi = axios.create({
  baseURL: process.env.REACT_APP_API_PAYMENT_URL,
  // baseURL: "http://localhost:3002",
  headers: {
    Authorization: process.env.REACT_APP_TOKEN,
    // "X-TenantID": store?.actualstore?.store || "smartman",
  },
});

export { catalogApi, collectionApi, paymentApi, realTimeApi, userApi };
