import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import React, { useState } from "react";
import { useHistory } from "react-router";
import MainUserAvatarIcon from "../../../images/sidebar/MainUserAvatarIcon.svg";
import { Div } from "../../atoms/Div";
import { Image } from "../../atoms/Image";
import { ItemSideBar } from "../../molecules/sidebar/ItemSideBar";

const useStyles = makeStyles(() => ({}));

export const DropDownMenu = ({ keycloak }) => {
  const classes = useStyles();
  const [direction] = useState("down");
  const [open, setOpen] = useState(false);
  const [hidden] = useState(false);
  const [itemsSideBar] = useState({
    user: {
      path: "/configuration#myAccount",
    },
    home: {
      path: "/home",
    },
    setting: {
      path: "/configuration",
    },
    newStore: {
      path: "/new-store",
    },
    logout: {
      path: "/home",
    },
  });

  let { location } = useHistory();
  const [routeActive, setrouteActive] = useState(location.pathname);

  const changeRouteActual = (value) => {
    setrouteActive(value);
  };

  const logout = () => {
    keycloak.logout();
  };

  const actions = [
    {
      icon: (
        <ItemSideBar
          value={0}
          path={itemsSideBar.user.path}
          actual={routeActive}
          changeRouteActual={changeRouteActual}
        />
      ),
      name: "Mi cuenta",
    },
    {
      icon: (
        <ItemSideBar
          value={1}
          path={itemsSideBar.home.path}
          actual={routeActive}
          changeRouteActual={changeRouteActual}
        />
      ),
      name: "Noticias",
    },
    {
      icon: (
        <ItemSideBar
          value={2}
          path={itemsSideBar.setting.path}
          actual={routeActive}
          changeRouteActual={changeRouteActual}
        />
      ),
      name: "Administrar",
    },
    {
      icon: (
        <ItemSideBar
          value={3}
          path={itemsSideBar.newStore.path}
          actual={routeActive}
          changeRouteActual={changeRouteActual}
        />
      ),
      name: "Nueva tienda",
    },
    {
      icon: <Div className="itemsidebar__img-4" onClick={() => logout()} />,
      name: "Log Out",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Div className={classes.root}>
        <Div className={classes.exampleWrapper}>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.testIcono}
            hidden={hidden}
            icon={<Image source={MainUserAvatarIcon}></Image>}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={handleClose}
              />
            ))}
          </SpeedDial>
        </Div>
      </Div>
    </>
  );
};
