import { toast } from "react-toastify";
import { set } from "../helpers/sessionStorage";
import { getListStores, getNewStore } from "../services";
import { types } from "../types/types";

export const startSaveStores = (email) => {
  return async (dispatch) => {
    if (email !== "") {
      try {
        const data = await getListStores(email);

        let stores = data?.vtexCredentialList || [];
        for (let i = 0; i < stores.length; i++) {
          stores[i].checked = false;
        }
        stores.sort(function (a, b) {
          if (a.store > b.store) {
            return 1;
          }
          if (a.store < b.store) {
            return -1;
          }
          return 0;
        });

        if (stores.length === 0 && window.location.pathname !== "/new-store") {
          window.location.replace(window.location.origin + "/new-store");
        }
        dispatch(saveStores(stores));
      } catch (error) {
        toast(`${error}`, {
          autoClose: 5000,
          type: "error",
          position: "bottom-left",
          hideProgressBar: true,
        });
      }
    }
  };
};

export const saveStores = (stores) => ({
  type: types.savestores,
  payload: stores,
});

export const startUpdateAppStore = (appstore, stores) => {
  return async (dispatch) => {
    try {
      const getStoreByName = await getNewStore({ appstore });
      set("saveStores", { actualstore: appstore, indexstore: getStoreByName });
      dispatch(updateAppStore(appstore, getStoreByName, stores));
    } catch (error) {
      toast(`${error}`, {
        autoClose: 5000,
        type: "error",
        position: "bottom-left",
        hideProgressBar: true,
      });
    }
  };
};

export const updateAppStore = (appstore, indexstore, stores) => ({
  type: types.updateappstore,
  payload: {
    actualstore: appstore,
    indexstore: indexstore,
    stores: stores,
  },
});
