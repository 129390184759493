import React from "react";

export const Div = ({
  children,
  className,
  onMouseEnter,
  onClick,
  onMouseLeave,
  hidden,
  dangerouslySetInnerHTML,
  onKeyDown,
  onKeyUp,
  ...props
}) => {
  return (
    <div
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      hidden={hidden}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      tabIndex="0"
      {...props}
    >
      {children}
    </div>
  );
};
