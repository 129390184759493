import { paymentApi, userApi } from "./config";

const getInfoUser = async ({ email }) => {
  try {
    const url = `/api/v1/user/vtexcredential/${email}`;
    let {
      data: { data, isSuccess },
    } = await userApi.get(url);
    if (!isSuccess) {
      return { error: "No se ha podido cargar la información del usuario" };
    } else {
      return data;
    }
  } catch (error) {
    throw new Error("No se ha podido cargar la información del usuario.");
  }
};

const editDataUser = async ({ password, schemaQuery }) => {
  try {
    const url = `/api/v1/user${
      password !== "" ? `/?password=${password}` : ""
    }`;
    const { data } = await userApi.post(url, schemaQuery);
    return data;
  } catch (error) {
    throw new Error("No se ha podido editar la información del usuario.");
  }
};

const inviteParner = async ({ invitedstring, email, store }) => {
  try {
    const url = `/api/v1/userPartner/invite?${invitedstring}`;
    const { data } = await userApi.post(url, null, {
      params: { email, store },
    });
    return data;
  } catch (error) {
    throw new Error("No se han podido agregar los partner.");
  }
};

const getListPartners = async ({ email, store }) => {
  try {
    let url = `/api/v1/userPartner/getUsersPartners`;
    let {
      data: { data },
    } = await userApi.get(url, {
      params: {
        email,
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido cargar el listado de partners.");
  }
};

/**
 * It's a function that deletes a partner from a user's list of partners.
 * </code>
 * @returns The data is being returned.
 */
const deletePartner = async ({ store, email, emailPartner }) => {
  try {
    const url = `/api/v1/userPartner/deleteUserPartner`;
    const { data } = await userApi.delete(url, {
      params: {
        email,
        emailPartner,
        store,
      },
    });
    return data;
  } catch (error) {
    throw new Error("El partner no se ha podido eliminar.");
  }
};

/**
 * It takes an object with an email, form, and id, and then it makes a put request to the url with the
 * form and the email as a parameter.
 * </code>
 * @returns {
 *   "id": "5e8d8f8f-b8d5-4c5e-b8d5-4c5e5e8d8f8f",
 *   "name": "test",
 *   "account": "test",
 *   "environment": "test",
 *   "token": "test",
 */
const updateStore = async ({ email, form, id }) => {
  try {
    const url = `/api/v1/vtexcredential/${id}`;
    const { data } = await userApi.put(url, form, {
      params: {
        username: email,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido editar la tienda");
  }
};

const newStore = async ({ email, form }) => {
  try {
    const url = "/api/v1/vtexcredential/{id}";
    const { data } = await userApi.put(url, form, {
      params: {
        username: email,
      },
    });
    return data;
  } catch (error) {
    throw new Error("No se ha podido crear la nueva tienda");
  }
};

/**
 * It sends a payment to the server.
 * @returns The data that is being returned is the data that is being sent to the server.
 */
const sendPayment = async ({ request }) => {
  try {
    const url = `/api/v1/subscription`;
    const { data } = await paymentApi.post(url, request);
    return data;
  } catch (error) {
    throw new Error("No se ha podido enviar el pago");
  }
};
const cancelSubscription = async (request) => {
  try {
    const url = `/api/v1/subscription`;

    const data = await paymentApi.put(url, { data: request });
    return data;
  } catch (error) {
    throw new Error("Error cancelando suscripción");
  }
};

const reactivateSubscription = async (request) => {
  try {
    const url = `/api/v1/subscription`;

    const data = await paymentApi.put(url, { data: request });
    return data;
  } catch (error) {
    throw new Error("Error cancelando suscripción");
  }
};
const getSubscriptionDetails = async ({ request }) => {
  try {
    const url = `/api/v1/subscription`;

    const { data } = await paymentApi.post(url, request);
    return data;
  } catch (error) {
    throw new Error("Error obteniendo detalles de la suscripción");
  }
};
const getCustomerSubscription = async (urlParams) => {
  try {
    const url = `/api/v1/customer-pasarela${urlParams}`;
    const { data } = await paymentApi.get(url);
    return data;
  } catch (error) {
    throw new Error("No se ha podido obtener información del usuario suscrito");
  }
};
const createNewCardSubscription = async (card) => {
  try {
    const url = `/api/v1/customer-pasarela/createadd-card-token-customer`;

    const { data } = await paymentApi.post(url, card);
    return data;
  } catch (error) {
    throw new Error("No se ha podido crear tarjeta");
  }
};
// params
// franchise: string;
// token: string;
// mask: string;
// customer_id: string;
const deleteCardSubscription = async (card) => {
  try {
    const url = `/api/v1/card-token/remove`;

    const { data } = await paymentApi.put(url, card);
    return data;
  } catch (error) {
    throw new Error("No se ha podido eliminar tarjeta");
  }
};

const setCardSubscriptionAsDefault = async (card) => {
  try {
    const url = `/api/v1/card-token/set-default`;

    const { data } = await paymentApi.put(url, card);
    return data;
  } catch (error) {
    throw new Error("No se ha podido agregar por defecto la tarjeta");
  }
};

export {
  getInfoUser,
  editDataUser,
  inviteParner,
  getListPartners,
  deletePartner,
  sendPayment,
  updateStore,
  newStore,
  getSubscriptionDetails,
  getCustomerSubscription,
  createNewCardSubscription,
  deleteCardSubscription,
  setCardSubscriptionAsDefault,
  // updateCustomerSubscription,
  // updateCardSubscription,
  // getCardSubscription,
  reactivateSubscription,
  cancelSubscription,
};
