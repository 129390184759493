import React from 'react'

export const SectionPoint = ({section, idParents, t}) => {
    return (
        <ul className="section-point">
            <li type="disc" dangerouslySetInnerHTML={{ __html: t(`quickdemo.${idParents.idTheme}.subthemes.${idParents.idSubTheme}.subsubtheme.${idParents.idSubsubTheme}.section.${idParents.idSection}.text`) }}>
            </li>
        </ul>
    )
}
