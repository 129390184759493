import i18next from "i18next";
import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import Loader from "react-loader-spinner";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import { store } from "./store/store";
import "./utils/i18n";

export const SmartmanApp = ({ userInfo, keycloak }) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18next={i18next}>
        <Suspense
          fallback={
            <div className="modal__wrapper">
              <Loader
                type="Oval"
                color="#1996FC"
                secondaryColor="Grey"
                height={100}
                width={100}
              />
            </div>
          }
        >
          <Router>
            <App userInfo={userInfo} keycloak={keycloak} />
          </Router>
        </Suspense>
      </I18nextProvider>
    </Provider>
  );
};
