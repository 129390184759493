import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearCategoriesToChart } from "../../../actions/reportCategories";
import { startUpdateAppStore } from "../../../actions/stores";
import { Button } from "../../atoms/Button";
import { Checkbox } from "../../atoms/Checkbox";
import { Div } from "../../atoms/Div";
import { Label } from "../../atoms/Label";
import { Lin } from "../../atoms/Lin";
import { P } from "../../atoms/P";
import { Span } from "../../atoms/Span";

const StoreSelect = memo(function StoreSelect() {
  let history = useHistory();
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.stores, shallowEqual);
  const { t } = useTranslation();
  const [storeselectstate, setstoreselectstate] = useState({
    custom: "store-selector__options-hide",
  });

  const hide = () => {
    if (storeselectstate.custom === "store-selector__options-hide") {
      setstoreselectstate({
        custom: "store-selector__options-show",
      });
    } else {
      setstoreselectstate({
        custom: "store-selector__options-hide",
      });
    }
  };

  const hideDive = () => {
    setstoreselectstate({ custom: "store-selector__options-hide" });
  };

  const selectStore = (e, id) => {
    let storeselected;
    let storesA = stores.stores;
    for (let i = 0; i < storesA.length; i++) {
      if (storesA[i].id !== id) {
        storesA[i].checked = false;
      } else {
        storesA[i].checked = true;
        storeselected = storesA[i];
      }
    }
    dispatch(clearCategoriesToChart());
    dispatch(startUpdateAppStore(storeselected, storesA));
    setstoreselectstate({
      custom: "store-selector__options-hide",
    });
    history.push("/home");
  };

  const formatStores = useCallback(() => {
    let storeselected;
    for (const store of stores.stores) {
      store.checked = false;
    }
    if (stores.stores.length > 0) {
      switch (stores.actualstore.store) {
        case "Selecciona una tienda":
          stores.stores[0].checked = true;
          storeselected = stores.stores[0];
          storeselected && dispatch(startUpdateAppStore(storeselected, stores));
          break;

        default:
          for (const store of stores.stores) {
            if (store === stores.actualstore.store) {
              store.checked = true;
              storeselected = store;
            }
          }
          storeselected &&
            dispatch(startUpdateAppStore(storeselected, stores.stores));
          break;
      }
    }

    setstoreselectstate({
      custom: "store-selector__options-hide",
    });
  }, [dispatch, stores]);

  useEffect(() => {
    if (stores.storeget) {
      formatStores();
    }
  }, [stores.storeget, formatStores]);

  if (stores.storeget && stores.stores.length === 0) {
    localStorage.setItem("qd", "true");
    return (
      <Div className="store-selector" onMouseLeave={hideDive}>
        {/* <Redirect to="/configuration"></Redirect> */}
        <Button className="store-selector__store-select" onClick={hide}>
          {t("header.shopsNotFound")}
        </Button>
        <Div className={storeselectstate.custom}>
          <Lin
            path="/configuration"
            className="store-select-options-show__item"
          >
            {t("header.addShop")}
          </Lin>
        </Div>
      </Div>
    );
  } else {
    localStorage.setItem("qd", "false");
    return (
      <Div className="store-selector" onMouseLeave={hideDive}>
        <P className="store-selector__p">
          {" "}
          {t("header.bill")} {">"} {stores.actualstore.store}
        </P>
        <Button className="store-selector__store-select" onClick={hide}>
          {stores.actualstore.store}
        </Button>
        <Div className={storeselectstate.custom}>
          {stores.stores.map((store, idx) => (
            <Div
              className="store-selector__item"
              key={`${store.store}_${idx}`}
              title={store.storeType}
            >
              <Div className="store-selector__container-check">
                <Label className="store-selector__checkbox">
                  <Checkbox
                    checked={store.checked}
                    onChange={(e) => selectStore(e.target.checked, store.id)}
                  />
                  <Span className="store-selector__check"></Span>
                </Label>
              </Div>
              {store.store}
            </Div>
          ))}
        </Div>
      </Div>
    );
  }
});
export default StoreSelect;
