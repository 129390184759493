import { catalogApi, userApi } from "./config";

const getListStores = async (email) => {
  try {
    let url = `/api/v1/user/vtexcredential/${email}`;
    let {
      data: { data },
    } = await userApi.get(url);

    return data;
  } catch (error) {
    throw new Error("No se ha cargado la lista de tiendas");
  }
};

const getNewStore = async ({ appstore }) => {
  try {
    let url = `/api/v1/Catalogue/indexstatus`;
    const {
      data: { data },
    } = await catalogApi.get(url, {
      params: {
        store: appstore.store,
      },
    });
    return data;
  } catch (error) {
    throw new Error(`No se ha podido cargar tienda ${appstore.store}`);
  }
};

export { getListStores, getNewStore };
