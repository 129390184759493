
import { types } from "../types/types";

const initialState = {
    categoriesToChart: []
};

export const reportCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.saveCategoriesToChart:
      return {
        categoriesToChart: action.payload.categoriesToChart
      };

      case types.clearCategoriesToChart:
        return {
          categoriesToChart: []
        };
    
    default:
      return state;
  }
};
